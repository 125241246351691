import React from 'react';

import Icon from '@mui/material/Icon';
// import {Navigate} from 'react-router-dom';
// import aliyunLogo from './sidebarLogos/aliyun.png';
// import azureLogo from './sidebarLogos/azure.svg';
// import digitaloceanLogo from './sidebarLogos/digitalocean.svg';
// import gcpLogo from './sidebarLogos/gcp.svg';
// import oracleOciLogo from './sidebarLogos/oracle-oci.svg';
// import tencentCloudLogo from './sidebarLogos/tencent-cloud.png';
import Logout from 'pages/Logout';
import {useTranslation} from 'react-i18next';
import generationLogo from './sidebarLogos/generation.svg';
import integrationLogo from './sidebarLogos/integration.svg';
import monitoringLogo from './sidebarLogos/monitoring.svg';
import shareLogo from './sidebarLogos/share.svg';
import slackLogo from './sidebarLogos/slack.svg';
import {DrCloudAppSwitcher} from './components/AppSwitcher';

const InDevelopmentPage = React.lazy(
	() => import('../InDevelopmentPage/InDevelopmentPage')
);

const Home = React.lazy(() => import('pages/DrCloud/Home/Home'));

// const ContainerDashboard = React.lazy(
// 	() => import('pages/DrCloud/ContainerDashboard/ContainerDashboard')
// );

// const ContainerScanDetails = React.lazy(
// 	() => import('pages/DrCloud/ContainerScanDetails/ContainerScanDetails')
// );
// const AwsAccount = React.lazy(
// 	() => import('pages/DrCloud/CloudProtect/CloudProviders/AWS/Account')
// );
// const EcrImageDetails = React.lazy(
// 	() =>
// 		import(
// 			'pages/DrCloud/CloudProtect/CloudProviders/AWS/ECR/EcrImageDetails'
// 		)
// );

// const Pricing = React.lazy(() => import('pages/Pricing'));
// const Billing = React.lazy(() => import('pages/Billing/Billing'));

const SearchPanel = React.lazy(() => import('pages/SearchPanel'));

const SettingsProfile = React.lazy(() => import('pages/Settings/Profile'));
const SettingsDeveloper = React.lazy(() => import('pages/Settings/Developer'));
const SettingsTokens = React.lazy(() => import('pages/Settings/Tokens'));
// const JiraIntegration = React.lazy(() => import('pages/Settings/Jira'));
const SettingsNotification = React.lazy(
	() => import('pages/Settings/Notification')
);
const SettingsChangePassword = React.lazy(
	() => import('pages/Settings/ChangePassword')
);
const SettingsDeleteAccount = React.lazy(
	() => import('pages/Settings/DeleteAccount')
);
const SettingsTheme = React.lazy(() => import('pages/Settings/Theme'));

const TeamManagement = React.lazy(() => import('pages/Team'));

const LicenseList = React.lazy(() => import('pages/License/LicenseList'));
const LicenseDetail = React.lazy(() => import('pages/License/LicenseDetail'));

const PolicyManagement = React.lazy(() => import('pages/NewPolicy'));

// const BinaryDashboard = React.lazy(
// 	() => import('pages/DrCloud/Binary/BinaryDashboard')
// );
// const BinaryDetailsWrapper = React.lazy(
// 	() => import('pages/DrCloud/Binary/components/BinaryDetailsWrapper')
// );

// const DockerHubAccount = React.lazy(
// 	() => import('pages/DrCloud/CloudProtect/CloudProviders/DockerHub/Account')
// );
// const DockerHubDetails = React.lazy(
// 	() =>
// 		import(
// 			'pages/DrCloud/CloudProtect/CloudProviders/DockerHub/Details/DockerHubDetails'
// 		)
// );
// const DockerHubTagScanDetails = React.lazy(
// 	() =>
// 		import(
// 			'pages/DrCloud/CloudProtect/CloudProviders/DockerHub/Details/DockerHubTagScanDetails'
// 		)
// );

const GitHubAccount = React.lazy(() => import('pages/Settings/Github'));
// const GitHubDetails = React.lazy(
// 	() => import('pages/DrCloud/CloudProtect/CloudProviders/GitHub/Details')
// );
// const GitHubCommitDetails = React.lazy(
// 	() => import('pages/DrCloud/CloudProtect/CloudProviders/GitHub/Commit')
// );
const RegistryList = React.lazy(() => import('pages/Registry/RegistryList'));
const RegistryDetail = React.lazy(
	() => import('pages/Registry/RegistryDetail')
);
const RegistryStreamDetail = React.lazy(
	() => import('pages/Registry/RegistryStreamDetail')
);

const Projects = React.lazy(() => import('pages/DrCloud/Projects'));
const ProjectDetails = React.lazy(
	() => import('pages/DrCloud/Projects/ProjectDetailsPage')
);

const ScanResult = React.lazy(() => import('pages/DrCloud/common/ScanResult'));

// const Webhook = React.lazy(() => import('pages/Webhook/WebhookList'));

const Integration = React.lazy(() => import('pages/DrCloud/Integration'));

/**
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

export const useDrCloudRoutes = () => {
	const {t} = useTranslation();

	const routes = [
		// {
		// 	type: 'collapse',
		// 	name: t('sidebar.asksbom'),
		// 	noCollapse: true,
		// 	key: 'asksbom',
		// 	icon: <img src={chatLogo} width="20" height="20" />,
		// 	href: 'https://tooltest.deepbits.com/chat',
		// 	component: <Home />,
		// },

		{
			type: 'collapse',
			icon: <img src={monitoringLogo} width="20" height="20" />,
			noCollapse: true,
			name: t('sidebar.dashboard'),
			key: 'dashboard',
			route: '/',
			component: <Home />,
		},
		{
			type: 'collapse',
			noCollapse: true,
			icon: <img src={generationLogo} width="20" height="20" />,
			name: t('sidebar.projects'),
			key: 'projects',
			route: '/projects',
			component: <Projects />,
			apps: [DrCloudAppSwitcher.DeepBuild],
		},
		{
			type: 'collapse',
			noCollapse: true,
			icon: <img src={generationLogo} width="20" height="20" />,
			name: t('sidebar.assets'),
			key: 'projects',
			route: '/projects',
			component: <Projects />,
			apps: [DrCloudAppSwitcher.DeepAsset],
		},
		{
			type: 'collapse',
			noCollapse: true,
			name: t('sidebar.sbom'),
			key: 'registry',
			route: '/registry',
			component: <RegistryList />,
			icon: <img src={shareLogo} width="20" height="20" />,
			apps: [DrCloudAppSwitcher.DeepBuild],
		},
		{
			type: 'collapse',
			name: t('sidebar.policies'),
			key: 'monitoring',
			icon: <img src={monitoringLogo} width="20" height="20" />,
			collapse: [
				{
					name: t('sidebar.policyRule'),
					key: 'policy',
					route: '/policy',
					component: <PolicyManagement />,
				},
				{
					name: t('sidebar.license'),
					key: 'license',
					route: '/license',
					component: <LicenseList />,
				},
				{
					name: t('sidebar.notification'),
					key: 'user-settings',
					route: '/settings/notifications',
					component: <SettingsNotification />,
				},
				// {
				// 	name: t('sidebar.fda'),
				// 	key: 'fda_regulation',
				// 	route: '/fda_regulation',
				// 	component: <InDevelopmentPage />,
				// },
			],
		},
		// {
		// 	type: 'collapse',
		// 	name: t('sidebar.integration'),
		// 	key: 'integration',
		// 	icon: <img src={integrationLogo} width="20" height="20" />,
		// 	collapse: [
		// 		{
		// 			type: 'title',
		// 			key: 'divider-assets',
		// 			name: t('sidebar.assets'),
		// 		},
		// 		// {
		// 		// 	name: t('sidebar.file'),
		// 		// 	route: '/file',
		// 		// 	key: 'files',
		// 		// 	component: <BinaryDashboard />,
		// 		// },
		// 		// {
		// 		// 	name: t('sidebar.images'),
		// 		// 	key: 'images',
		// 		// 	route: '/images',
		// 		// 	component: <ContainerDashboard />,
		// 		// },
		// 		// {
		// 		// 	name: t('sidebar.github'),
		// 		// 	key: 'github-group',
		// 		// 	icon: <img width={24} src={githubLogo} />,
		// 		// 	collapse: [
		// 		// 		{
		// 		// 			type: 'github',
		// 		// 			name: t('sidebar.github'),
		// 		// 			key: 'github',
		// 		// 			icon: <img width={24} src={githubLogo} />,
		// 		// 		},
		// 		// 	],
		// 		// },
		// 		// {
		// 		// 	type: 'collapse',
		// 		// 	name: t('sidebar.dockerHub'),
		// 		// 	key: 'docker_hub',
		// 		// 	icon: <img width={24} src={dockerLogo} />,
		// 		// 	collapse: [
		// 		// 		{
		// 		// 			key: 'docker_hub_account',
		// 		// 			route: '/cloud/dockerhub/account',
		// 		// 			name: t('sidebar.dockerHub.account'),
		// 		// 			component: <DockerHubAccount />,
		// 		// 		},
		// 		// 		{
		// 		// 			key: 'docker_hub_details',
		// 		// 			route: '/cloud/dockerhub/details',
		// 		// 			name: t('sidebar.dockerHub'),
		// 		// 			component: <DockerHubDetails />,
		// 		// 		},
		// 		// 	],
		// 		// },
		// 		{
		// 			name: t('sidebar.github'),
		// 			key: 'github_sidebar',
		// 			route: '/cloud/github',
		// 			component: <Navigate to="/settings/github" replace />,
		// 		},
		// 		{
		// 			key: 'docker_hub_account',
		// 			route: '/cloud/dockerhub/account',
		// 			name: t('sidebar.dockerHub'),
		// 			component: <DockerHubAccount />,
		// 		},
		// 		{
		// 			key: 'aws_account',
		// 			route: '/cloud/aws/account',
		// 			name: t('sidebar.aws'),
		// 			component: <AwsAccount />,
		// 		},
		// 		{
		// 			type: 'title',
		// 			key: 'divider-alert',
		// 			name: t('sidebar.alert'),
		// 		},
		// 		{
		// 			name: t('sidebar.webhook'),
		// 			key: 'webhook',
		// 			route: '/webhook',
		// 			component: <Webhook />,
		// 		},
		// 		{
		// 			name: t('sidebar.jira'),
		// 			key: 'JiraIntegration',
		// 			route: '/settings/jira',
		// 			component: <JiraIntegration />,
		// 		},
		// 		{
		// 			name: t('sidebar.datadog'),
		// 			key: 'data_dog',
		// 			route: '/datadog',
		// 			component: <InDevelopmentPage />,
		// 		},
		// 		{
		// 			name: t('sidebar.email'),
		// 			key: 'email',
		// 			route: '/email',
		// 			component: <InDevelopmentPage />,
		// 		},
		// 	],
		// },
		{type: 'divider', key: 'divider-2', name: 'divider-2'},
		{
			name: 'Integration',
			key: 'integration',
			route: '/integration',
			type: 'collapse',
			noCollapse: true,
			icon: <img src={integrationLogo} width="20" height="20" />,
			component: <Integration />,
		},
		{
			type: 'collapse',
			name: 'Help & Docs',
			noCollapse: true,
			key: 'help-docs',
			icon: <Icon fontSize="medium">help</Icon>,
			disabled: true,
			href: 'https://docs.deepbits.com',
		},
		{
			type: 'collapse',
			name: 'Join Slack',
			noCollapse: true,
			key: 'join-slack',
			icon: <img width={20} src={slackLogo} />,
			disabled: true,
			href: 'https://join.slack.com/t/deepbits/shared_invite/zt-1bx3xegmf-wW8UBBotNFyu6Ai9CYb~JQ',
		},
		// {
		// 	type: 'title',
		// 	title: miniSidenav ? 'In dev' : 'In Development',
		// 	key: 'title-wip',
		// 	name: 'In Development',
		// 	disabled: true,
		// },
		// {
		// 	type: 'collapse',
		// 	name: 'Azure',
		// 	noCollapse: true,
		// 	key: 'azure',
		// 	icon: <img width={20} src={azureLogo} />,
		// 	disabled: true,
		// },
		// {
		// 	type: 'collapse',
		// 	name: 'GCP',
		// 	noCollapse: true,
		// 	key: 'gcp',
		// 	icon: <img width={22} src={gcpLogo} />,
		// 	disabled: true,
		// },
		// {
		// 	type: 'collapse',
		// 	name: 'Oracle OCI',
		// 	noCollapse: true,
		// 	key: 'oracleOci',
		// 	icon: <img width={24} src={oracleOciLogo} />,
		// 	disabled: true,
		// },
		// {
		// 	type: 'collapse',
		// 	name: 'Digital Ocean',
		// 	noCollapse: true,
		// 	key: 'digitalocean',
		// 	icon: <img width={22} src={digitaloceanLogo} />,
		// 	disabled: true,
		// },
		// {
		// 	type: 'collapse',
		// 	name: 'Aliyun',
		// 	noCollapse: true,
		// 	key: 'aliyun',
		// 	icon: <img width={24} src={aliyunLogo} />,
		// 	disabled: true,
		// },
		// {
		// 	type: 'collapse',
		// 	name: 'Tencent Cloud',
		// 	noCollapse: true,
		// 	key: 'tencentCloud',
		// 	icon: <img width={26} src={tencentCloudLogo} />,
		// 	disabled: true,
		// },
		// {type: 'divider', key: 'divider-1', name: 'divider-1'},
		// Above routes won't display on sidebar

		// {
		// 	key: 'dashboard',
		// 	route: '/dashboard',
		// 	component: <Dashboard />,
		// },
		// {
		// 	key: 'user-account',
		// 	route: '/pricing',
		// 	component: <Pricing />,
		// },
		// {
		// 	key: 'user-billing',
		// 	route: '/billing',
		// 	component: <Billing />,
		// },
		{
			key: 'team-management',
			route: '/team',
			component: <TeamManagement />,
		},
		{
			key: 'logout',
			route: '/logout',
			component: <Logout />,
		},
		// {
		// 	route: '/cloud/aws/ecr/:repositoryId/images/:imageId/:imageTag',
		// 	key: 'EcrImageDetails',
		// 	component: <EcrImageDetails />,
		// },
		// {
		// 	route: '/cloud/aws/ecr/:repositoryId/images/',
		// 	key: 'EcrImagesList',
		// 	component: <Navigate to="/cloud/aws/ecr/" replace />,
		// },
		// {
		// 	route: '/cloud/aws/ecr/:repositoryId/',
		// 	key: 'EcrRepositoryDetail',
		// 	component: <Navigate to="/cloud/aws/ecr/" replace />,
		// },
		// {
		// 	route: '/cloud/dockerhub/:repoId/:tagId',
		// 	key: 'DockerHubTagScanDetails',
		// 	component: <DockerHubTagScanDetails />,
		// },
		// {
		// 	route: '/images/:imageId',
		// 	key: 'ContainerScanDetails',
		// 	component: <ContainerScanDetails />,
		// },
		// {
		// 	route: '/images/:imageId/upload',
		// 	key: 'ContainerScanDetailsUpload',
		// 	component: <ContainerScanDetails />,
		// },
		{
			route: '/settings/change-password',
			key: 'ChangePassword',
			component: <SettingsChangePassword />,
		},
		{
			route: '/settings/profile',
			key: 'SettingsProfile',
			component: <SettingsProfile />,
		},
		{
			route: '/settings/api',
			key: 'SettingsDeveloper',
			component: <SettingsDeveloper />,
		},
		{
			route: '/settings/tokens',
			key: 'SettingsTokens',
			component: <SettingsTokens />,
		},
		{
			route: '/settings/theme',
			key: 'SettingsTheme',
			component: <SettingsTheme />,
		},
		{
			route: '/settings/delete-account',
			key: 'SettingsDeleteAccount',
			component: <SettingsDeleteAccount />,
		},
		{
			route: '/license/detail',
			key: 'LicenseDetail',
			component: <LicenseDetail />,
		},
		{
			route: '/project/:projectId',
			key: 'ProjectDetails',
			component: <ProjectDetails />,
		},
		{
			route: '/project/:projectId/:assetId/:streamId',
			key: 'ProjectScanResult',
			component: <ScanResult />,
		},
		// {
		// 	route: '/file/:id',
		// 	key: 'BinaryDetails',
		// 	component: <BinaryDetailsWrapper />,
		// },
		// {
		// 	route: '/file/import/:id',
		// 	key: 'BinaryImport',
		// 	component: <BinaryImport />,
		// },
		// {
		// 	route: '/file/import_popular/:id',
		// 	key: 'BinaryImportPopular',
		// 	component: <BinaryImport isPopularSample />,
		// },
		{
			route: '/search',
			key: 'Search',
			component: <SearchPanel />,
		},
		{
			key: 'github_hub_account',
			route: '/settings/github',
			component: <GitHubAccount />,
		},
		// {
		// 	key: 'github_details',
		// 	route: '/cloud/github/:githubAccountId/repos',
		// 	component: <GitHubDetails />,
		// },
		// {
		// 	key: 'github_commit_details',
		// 	route: '/cloud/github/:githubAccountId/repos/:owner/:repository/:sha',
		// 	component: <GitHubCommitDetails />,
		// },
		{
			key: 'registry_detail',
			route: '/registry/:id',
			component: <RegistryDetail />,
		},
		{
			key: 'registry_scan_detail',
			route: '/registry/:id/stream/:streamId/scan-result',
			component: <RegistryStreamDetail />,
		},
		{
			key: 'data_dog',
			route: '/datadog',
			component: <InDevelopmentPage />,
		},
		{
			key: 'email',
			route: '/email',
			component: <InDevelopmentPage />,
		},
		{
			key: 'policy_expanded',
			route: '/policy/:id',
			component: <PolicyManagement />,
		},
	];

	return routes;
};
